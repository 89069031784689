import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Card, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import styles from './ProductModal.module.scss';
import Swal from 'sweetalert2';
import { useSession } from '../../../components/Authentication/Auth/SessionContext'; 

const ProductModal = ({ show, handleClose, selectedProduct, fetchProducts }) => {
  const TOKEN = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const APICategories = process.env.REACT_APP_API_URL + '/api/CategoriaProducto';
  const APIUnit = process.env.REACT_APP_API_URL + '/api/ProductoUnidad';
  const API_PRODUCT = process.env.REACT_APP_API_URL + '/api/Product';
  // Detectar si el producto está siendo editado o creado
  const isEditing = selectedProduct !== null;
  // Agregar el estado `isProcessing` para manejar el bloqueo del botón de guardar
  const [isProcessing, setIsProcessing] = useState(false);
  const { session, setSession } = useSession();

  // Estado centralizado para datos del producto
  const [productData, setProductData] = useState({
    id:0,
    descripcion: '',
    idUnidadVenta: 0,
    idUnidadCompra: 0,
    idCategoria: 0,
    costo: 0,
    precio: 0,
    itbiss: 0,
    imagen:'',
    codigoQr: '',
    codigoBarra: '',
    inventariable: true,
    idUsuario: session.user?.Id,
    idSucursal: session.sucursal?.Id,
    porcientoDescuento: 0,
    permiteDescuento: false,
    disponibilidad: 0,
    habitacion: false,
    precedimientoDental: false,
    stockMinimo: 0,
    stockMaximo: 0,
    elaborado: false,
    cuentaCostoVenta: '134'
  });

  const handleFieldChange = (field, value) => {
    const numericValue = value !== '' ? parseFloat(value) : null;

    setProductData((prevData) => {
      const updatedData = { ...prevData, [field]: numericValue };

      // Realiza cálculos automáticos según el campo modificado
      if (field === 'precio' && numericValue !== null) {
        updatedData.utilidad = numericValue > 0 && updatedData.costo !== null
          ? ((updatedData.precio/(1+(updatedData.itbiss/100))-updatedData.costo))
          : 0; // Evitar NaN
      } else if (field === 'utilidad' && numericValue !== null) {
        updatedData.precio = numericValue >= 0 && updatedData.costo !== null
          ? (updatedData.costo + (updatedData.costo * (updatedData.utilidad/100)) + ((updatedData.costo * ( updatedData.utilidad / 100) + updatedData.costo) * (updatedData.itbiss/100)))
          : 0; // Evitar NaN
      } else if (field === 'costo' || field === 'itbiss') {
        // Recalcula precio y utilidad si el costo o el ITBIS cambian
        if (updatedData.utilidad != null) {
          console.log('utilidad',updatedData)
          updatedData.precio = (updatedData.costo + (updatedData.costo * (updatedData.utilidad/100)) + ((updatedData.costo * ( updatedData.utilidad / 100) + updatedData.costo)  * (updatedData.itbiss/100)));
        }
        else if (updatedData.precio != null) {
          updatedData.utilidad = numericValue > 0 && updatedData.costo !== null
          ? ((updatedData.precio/(1+(updatedData.itbiss/100))-updatedData.costo))
          : 0; // Evitar NaN
        }
      }

      return updatedData;
    });
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryRes, unitRes] = await Promise.all([
          axios.get(APICategories, { headers: { Authorization: `Bearer ${TOKEN}` } }),
          axios.get(APIUnit, { headers: { Authorization: `Bearer ${TOKEN}` } })
        ]);

        setCategories(categoryRes.data.dataList.map(c => ({ value: c.id, label: c.descripcion })));
        setUnits(unitRes.data.dataList.map(u => ({ value: u.id, label: u.descripcion })));
      } catch (err) {
        setError(err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [APICategories, APIUnit, TOKEN]);

  // Actualizar datos del producto cuando se selecciona para edición
  useEffect(() => {
      if (selectedProduct) {
        console.log('Selected Product:', selectedProduct); // Verifica los datos del producto seleccionado
        setProductData({
          id: selectedProduct.id || 0,
          descripcion: selectedProduct.descripcion || '',
          idCategoria: categories.find((c) => c.value === selectedProduct.idCategoria) || 0,
          idUnidadCompra: units.find((u) => u.value === selectedProduct.idUnidadCompra) || 0,
          idUnidadVenta: units.find((u) => u.value === selectedProduct.idUnidadVenta) || 0,
          stockMinimo: selectedProduct.stockMinimo || 0,
          stockMaximo: selectedProduct.stockMaximo || 0,
          costo: selectedProduct.costo || 0,
          precio: selectedProduct.precio || 0,
          itbiss: selectedProduct.itbiss || 0,
          codigoQr: selectedProduct.codigoQr || '',
          codigoBarra: String(selectedProduct.codigoBarra) || '',
          imagen: selectedProduct.imagen || '',
          cuentaCostoVenta:  String(selectedProduct.cuentaCostoVenta) || '134',
          permiteDescuento: selectedProduct.permiteDescuento || false,
          porcientoDescuento: selectedProduct.permiteDescuento ? selectedProduct.porcientoDescuento : 0, // Restablece el descuento solo si permiteDescuento es true
          elaborado: selectedProduct.elaborado || false,
          inventariable: selectedProduct.inventariable || false,
          idUsuario:session.user?.Id,
          idSucursal: session.sucursal?.Id,
          disponibilidad: selectedProduct.disponibilidad || 0,
          habitacion: selectedProduct.habitacion || false,
          precedimientoDental: selectedProduct.precedimientoDental || false,
          categoria: categories.find((c) => c.value === selectedProduct.idCategoria)?.label || '',
          unidadCompra: units.find((u) => u.value === selectedProduct.idUnidadCompra)?.label || '',
          unidadVenta: units.find((u) => u.value === selectedProduct.idUnidadVenta)?.label || '',
        });
      } 
  }, [selectedProduct, categories, units]);
  
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProductData((prev) => ({
      ...prev,
      [id]: id === 'codigoBarra' || id === 'cuentaCostoVenta' ? value : isNaN(value) || value === '' ? value : Number(value),
    }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setProductData(prev => {
      const updatedData = { ...prev, [name]: checked };
      if (name === 'permiteDescuento' && !checked) updatedData.porcientoDescuento = 0;
      return updatedData;
    });
  };

  useEffect(() => {
    if (isEditing && selectedProduct?.imagen) {
      setImagePreview(`data:image/jpeg;base64,${selectedProduct.imagen}`);
    }
  }, [isEditing, selectedProduct]);

// Función para manejar la carga de la imagen (de archivo) y convertirla a base64
const handleImageChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const fileType = file.type.split('/')[1];
    const allowedTypes = ['jpeg', 'png'];
    if (!allowedTypes.includes(fileType)) {
      Swal.fire('Error', 'Solo se permiten archivos .jpg o .png', 'error');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      // Almacena la imagen convertida a base64
      setImagePreview(reader.result); // Vista previa en base64
      setProductData(prev => ({ ...prev, imagen: reader.result.split(',')[1] })); // Almacena solo la parte base64 sin el encabezado
    };
    reader.readAsDataURL(file); // Lee el archivo como base64
  }
};

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Lista de campos requeridos con sus nombres legibles
    const requiredFields = [
      { key: "descripcion", label: "Descripción" },
      { key: "idCategoria", label: "Categoría" },
      { key: "stockMaximo", label: "Stock Máximo" },
      { key: "idUnidadCompra", label: "Unidad de Compra" },
      { key: "idUnidadVenta", label: "Unidad de Venta" },
      { key: "costo", label: "Costo" },
      { key: "precio", label: "Precio" },
      { key: "cuentaCostoVenta", label: "Cuenta Costo Venta" },
    ];

     // Encuentra los campos faltantes
     const missingFields = requiredFields
     .filter((field) => !productData[field.key])
     .map((field) => `<li><b>${field.label}</b></li>`);

    
     if (missingFields.length > 0) {
      // Crea un mensaje de error con los campos faltantes en lista
      const errorMessage = `
        Por favor complete los siguientes campos requeridos:
        <ul style="text-align: left; margin-left: 35px; list-style-type: disc;">
          ${missingFields.join("")}
        </ul>
      `;
      Swal.fire({
        title: "Error",
        html: errorMessage, // Usar 'html' en lugar de 'text'
        icon: "error",
      });
      return;
    }

    if (productData.precio < productData.costo) {
      Swal.fire('Error', 'El precio debe ser mayor o igual al costo.', 'error');
      return;
    }
    
    setIsProcessing(true); // Bloquear el botón de guardar
  
    try {
      setLoading(true);
      const productDataToSend = {
        ...productData,
        idCategoria: productData.idCategoria?.value || 0,
        idUnidadCompra: productData.idUnidadCompra?.value || 0,
        idUnidadVenta: productData.idUnidadVenta?.value || 0,
        codigoBarra: String(productData.codigoBarra),
        cuentaCostoVenta: String(productData.cuentaCostoVenta),
        id: productData.id || 0,
        categoria: String(productData.categoria),
        unidadVenta: String(productData.unidadVenta),
        unidadCompra: String(productData.unidadCompra),
      };
  
      console.log('productDataToSend ',productDataToSend);
  
      const apiCall = isEditing 
        ? axios.put(API_PRODUCT, productDataToSend, { headers: { Authorization: `Bearer ${TOKEN}` } })
        : axios.post(API_PRODUCT, productDataToSend, { headers: { Authorization: `Bearer ${TOKEN}` } });
  
      const response = await apiCall;
  
      if (response.status === (isEditing ? 200 : 201)) {
        Swal.fire({
          icon: 'success',
          title: isEditing ? 'Producto editado con éxito' : 'Producto agregado con éxito',
          text: isEditing ? 'El producto ha sido actualizado correctamente.' : 'El producto ha sido agregado correctamente.'
        });
  
        // Actualizar directamente el estado de productos en el componente padre
        fetchProducts(productDataToSend); // Llama a la función que actualiza la data del grid
  
        handleClose();
      }
    } catch (error) {
      console.error('Error al guardar el producto:', error);
    } finally {
      setLoading(false);
      setIsProcessing(false); // Desbloquear el botón después de la operación
    }
  };  

  return (
    <Modal
    show={show}
    onHide={handleClose}
    dialogClassName={styles['custom-modal-width']} // Clase personalizada para el ancho
    centered // Para centrar el modal
  >
    <Modal.Header closeButton className="bg-primary text-white" style={{ border: '2px solid red' }}>
      <Modal.Title className="fw-bold fs-4">{isEditing ? 'Editar Producto' : 'Agregar Producto'}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Card className={styles.productModalCard}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col lg={3} md={12}>
                <Form.Group controlId="descripcion">
                  <Form.Label className="required-label fw-bold fs-6">Descripción</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nombre del Producto"
                    value={productData.descripcion}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                  <Form.Group controlId="idCategoria">
                    <Form.Label className="required-label fw-bold fs-6">Categoría</Form.Label>
                    <Select
                    required
                      options={categories}
                      value={productData.idCategoria}
                      onChange={(selected) =>
                        setProductData((prevData) => ({
                          ...prevData,
                          idCategoria: selected,
                        }))
                      }
                      placeholder="Seleccione"
                    />
                  </Form.Group>
                </Col>

              <Col lg={3} md={12}>
                <Form.Group controlId="stockMinimo">
                  <Form.Label className="required-label fw-bold fs-6">Existencia Mínima</Form.Label>
                  <InputGroup>
                    <Form.Control 
                      required
                      type="number" 
                      placeholder="Ej. 2"
                      value={productData.stockMinimo}
                      onChange={handleInputChange} />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="stockMaximo">
                  <Form.Label className="required-label fw-bold fs-6">Existencia Máxima</Form.Label>
                  <InputGroup>
                    <Form.Control 
                      required
                      type="number" 
                      placeholder="Ej. 100"
                      value={productData.stockMaximo}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg={3} md={12}>
                <Form.Group className="d-flex flex-column justify-content-center h-100">
                  <Form.Check
                    label="Inventariable"
                    type="switch"
                    className="custom-switch ps-0 mg-b-0"
                    checked={productData.inventariable}
                    onChange={handleSwitchChange}
                    name="inventariable"
                  />
                  <Form.Check
                    label="Procedimiento Dental"
                    type="switch"
                    className="custom-switch ps-0 mg-b-0"
                    checked={productData.precedimientoDental}
                    onChange={handleSwitchChange}
                    name="precedimientoDental"
                  />
                  <Form.Check
                    label="Permite Descuento"
                    type="switch"
                    className="custom-switch ps-0 mg-b-0"
                    checked={productData.permiteDescuento}
                    onChange={handleSwitchChange}
                    name="permiteDescuento"
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="porcientoDescuento">
                  <Form.Label className="fw-bold fs-6">Descuento</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>%</InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Ej. 10"
                      value={productData.porcientoDescuento || 0}
                      onChange={handleInputChange}
                      disabled={!productData.permiteDescuento} // Desactiva el campo cuando el switch está apagado
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                  <Form.Group controlId="idUnidadCompra">
                  <Form.Label className="required-label fw-bold fs-6">Formato de Compra</Form.Label>
                    <Select
                      required
                      options={units}
                      value={productData.idUnidadCompra}
                      onChange={(selected) =>
                        setProductData((prevData) => ({
                          ...prevData,
                          idUnidadCompra: selected,
                        }))
                      }
                      placeholder="Seleccione"
                    />
                  </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                  <Form.Group controlId="idUnidadVenta">
                  <Form.Label className="required-label fw-bold fs-6">Formato de Venta</Form.Label>
                    <Select
                      required
                      options={units}
                      value={productData.idUnidadVenta}
                      onChange={(selected) =>
                        setProductData((prevData) => ({
                          ...prevData,
                          idUnidadVenta: selected,
                        }))
                      }
                      placeholder="Seleccione"
                    />
                  </Form.Group>
                </Col>
            </Row>
            <Row className="mb-4">
              <Col lg={3} md={12}>
                <Form.Group controlId="costo">
                  <Form.Label className="required-label fw-bold fs-6">Costo</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Ingrese el costo"
                      value={productData.costo}
                      onChange={(e) => handleFieldChange('costo', e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
                <Col lg={2} md={6}>
                  <Form.Group controlId="utilidad">
                    <Form.Label className="required-label fw-bold fs-6">Utilidad</Form.Label>
                    <InputGroup>
                    <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Ingrese la utilidad"
                        value={productData.utilidad}
                        onChange={(e) => handleFieldChange('utilidad', e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={1} md={6}>
                <Form.Group controlId="itbiss">
                  <Form.Label className="required-label fw-bold fs-6">ITBIS</Form.Label>
                  <Form.Control
                    as="select"
                    value={productData.itbiss}
                    onChange={(e) => handleFieldChange('itbiss', e.target.value)}
                  >
                    <option value="0">0%</option>
                    <option value="16">16%</option>
                    <option value="18">18%</option>
                  </Form.Control>
                </Form.Group>
                </Col>
              <Col lg={3} md={12}>
              <Form.Group controlId="costo">
                  <Form.Label className="required-label fw-bold fs-6">Precio</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Ingrese el precio"
                      value={productData.precio}
                      onChange={(e) => handleFieldChange('precio', e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="codigoBarra">
                  <Form.Label className="fw-bold fs-6">Código de Barra</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ej. ElQuesoAzul1235"
                    value={productData.codigoBarra}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
                          </Row>
                          <Row>
              <Col lg={6} md={12}>
                <Form.Group controlId="imagen">
                  <Form.Label className="fw-bold fs-6">Imagen</Form.Label>
                  <Form.Control
                    type="file"
                     accept=".jpg,.png"
                    onChange={handleImageChange}
                  />
                  {imagePreview && <img src={imagePreview} alt="Vista previa" style={{ width: '150px', height: '150px', marginTop: '10px' }} />}
                  <Form.Control.Feedback type="invalid">Archivo válido</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={12}>
                <Form.Group controlId="cuentaCostoVenta">
                  <Form.Label className="required-label fw-bold fs-6">Cuenta Costo Venta</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Ej. 134"
                    value={productData.cuentaCostoVenta}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Card.Body>
      </Card>
    </Modal.Body>
    <Modal.Footer>
      <Button className='bg-danger border-0' onClick={handleClose}>
        Cerrar
      </Button>
      <Button 
        className='bg-success border-0'
        onClick={handleSubmit} 
        disabled={isProcessing} // Bloquear el botón cuando esté en procesamiento
      >
        {isProcessing ? 'Guardando...' : 'Guardar Producto'}
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default ProductModal; 
