import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';import { Table } from 'react-bootstrap';


const ProductExistenceModal = ({ show, onClose, product, updateProductExistence, userId, fetchProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [isIncrement, setIsIncrement] = useState(true);
  const [idDivisionAlmacen, setIdDivisionAlmacen] = useState(null);

  const handleShowModal = (incrementa, idDivision) => {
    setIsIncrement(incrementa);
    console.log(idDivision)
    setIdDivisionAlmacen(idDivision);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = async () => {
    if (isNaN(quantity) || parseFloat(quantity) <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe ingresar una cantidad válida.',
      });
      return;
    }
    const user = localStorage.getItem('user');
    const userObject = user ? JSON.parse(user) : null;
    console.log(userObject)
    console.log(userObject.Id)
    const requestData = {
      idProducto: product.id,
      idDivisionAlmacen,
      cantidad: parseFloat(quantity),
      idUsuario: userObject.Id,
      incrementa: isIncrement,
    };
console.log(requestData)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/API/Product/AjusteInventario`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {

        const updatedProduct = { ...product,
          existenciaTotal: requestData.incrementa ? (product.existenciaTotal + requestData.cantidad) : (product.existenciaTotal - requestData.cantidad),
        };
        
        // Actualizamos la existencia en el detalle correspondiente
        updatedProduct.detalleExistencia = updatedProduct.detalleExistencia.map((detalle) => {
          
          if (detalle.idAlmacen === idDivisionAlmacen) {
            // Ajustamos la existencia según si es una entrada o salida
            detalle.existencia = isIncrement
              ? detalle.existencia + parseFloat(quantity)
              : detalle.existencia - parseFloat(quantity);
          }
          return detalle;
        });
        
        console.log(updatedProduct)

        Swal.fire({
          icon: 'success',
          title: 'Operación exitosa',
          text: 'El inventario se ha actualizado correctamente.',
        });

        fetchProducts(updatedProduct);

        handleCloseModal()
        onClose(); // Cierra el modal principal
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo realizar el ajuste de inventario.',
        });
      }
    } catch (error) {
      console.error('Error al ajustar el inventario:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al realizar el ajuste.',
      });
    }
  };

  if (!product) return null;

  return (
    <>
      <Modal show={show} onHide={onClose} size="lg" centered>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title className="fw-bold fs-4">Existencia Total del Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{product.descripcion}</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Almacén</th>
                <th>Existencia</th>
                <th>Acción</th>

              </tr>
            </thead>
            <tbody>
              {product.detalleExistencia.map((detalle, index) => (
                <tr key={index}>
                  <td>{detalle.almacen}</td>
                  <td>{detalle.existencia}</td>
                  <td>
                    <Button
                      className="me-2 bg-success border-0"
                      onClick={() => handleShowModal(true, detalle.idAlmacen)}
                    >
                      Entrada
                    </Button>
                    <Button
                      className="me-2 bg-danger border-0"
                      onClick={() => handleShowModal(false, detalle.idAlmacen)}
                    >
                      Salida
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button className='bg-danger border-0' onClick={onClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de ajuste de cantidad */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title className="fw-bold fs-4">{isIncrement ? 'Entrada de Producto' : 'Salida de Producto'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formQuantity">
              <Form.Label className="fw-bold">Cantidad</Form.Label>
              <Form.Control
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Ingrese la cantidad"
                min="0"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='bg-danger border-0' onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button className='bg-success border-0' onClick={handleSave}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductExistenceModal;
