import React from 'react';
import styles from './Dashboard.module.scss';
import { Link } from 'react-router-dom';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { Card, Col, OverlayTrigger, ProgressBar, Row, Tooltip, Table, Tab, Nav } from 'react-bootstrap';
import { AllProduct, Worldmap, Shipped, Pending, Cancelled, SalesAnalytics, RecentOrder, TotalUser, TotalProfit, Totalexpenses, TotalCost, } from '../../Data/DataDashBoard/DashBoardData';

const Dashboard = () => {

  return (

    <div className={styles.Dashboard}>
      <PageHeader titles="Dashboard" active="Dashboard" items={['Home']} />

      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex" >
                    <div className="mt-2">
                      <h6 className="">Total Usuarios activos</h6>
                      <h2 className="mb-0 number-font">30</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1">
                        <TotalUser />
                      </div>
                    </div>
                  </div>
                  <span className="text-muted fs-12"><span className="text-secondary mx-2"><i
                    className="fe fe-arrow-up-circle  text-secondary"></i> 5%</span>
                    en este año</span>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Ganancias totales</h6>
                      <h2 className="mb-0 number-font">67,987</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1">
                        <TotalProfit />
                      </div>
                    </div>
                  </div>
                  <span className="text-muted fs-12"><span className="text-pink mx-2"><i
                    className="fe fe-arrow-down-circle text-pink"></i> 0.75%</span>
                    últimos 6 días</span>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Ventas totales</h6>
                      <h2 className="mb-0 number-font">$176,965</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1">
                        <Totalexpenses />
                      </div>
                    </div>
                  </div>
                  <span className="text-muted fs-12"><span className="text-green mx-2"><i
                    className="fe fe-arrow-up-circle text-green"></i> 0.9%</span>
                    Últimos 9 días</span>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Costo totales</h6>
                      <h2 className="mb-0 number-font">$59,765</h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1">
                        <TotalCost />
                      </div>
                    </div>
                  </div>
                  <span className="text-muted fs-12"><span className="text-warning mx-2"><i
                    className="fe fe-arrow-up-circle text-warning"></i> 0.6%</span>
                    último año</span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12} xl={8} xxl={9}>
          <Card>
            <Card.Header>
              <Card.Title as="h3">Análisis de ventas</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="chartjs-wrapper-demo myChartSaah">
                <SalesAnalytics />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} sm={12} lg={12} xl={4} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Body className="pb-0 bg-white">
              <Card.Title as="h3" className="text-black">Pedidos recientes</Card.Title>
              <div className="chartjs-wrapper-demo">
                <RecentOrder />
              </div>
            </Card.Body>
            <div id="flotback-chart" className="flot-background"></div>
            <Card.Body>
              <div className="d-flex mb-4 mt-3">
                <div
                  className="avatar avatar-md bg-blue text-white bradius me-3">
                  <i className="fe fe-check"></i>
                </div>
                <div className="">
                  <h6 className="mb-1 fw-semibold">Pedidos entregados</h6>
                  <p className="fw-normal fs-12"> <span className="text-success mx-1">3.5%</span>
                    aumentó </p>
                </div>
                <div className=" ms-auto my-auto">
                  <p className="fw-bold fs-20"> 1,768 </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="avatar  avatar-md bg-red text-white bradius me-3">
                  <i className="fe fe-x"></i>
                </div>
                <div className="">
                  <h6 className="mb-1 fw-semibold">Pedidos cancelados</h6>
                  <p className="fw-normal fs-12"> <span className="text-success mx-1">1.2%</span>
                  aumentó </p>
                </div>
                <div className=" ms-auto my-auto">
                  <p className="fw-bold fs-20 mb-0"> 3,675 </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card className="overflow-hidden">
            <Card.Header>
              <div>
                <Card.Title as="h3">Ventas por sucursal</Card.Title>
              </div>
            </Card.Header>
            <Card.Body className="p-0 mt-2">
              <div className="text-center">
                <Worldmap />
              </div>
              <div className="table-responsive mt-2 text-center">
                <Table className="text-nowrap border-dashed mb-0">
                  <thead>
                    <tr>
                      <th className="text-info">Sucursal 1</th>
                      <th className="text-danger">Sucursal 2</th>
                      <th className="text-warning">Sucursal 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-4">17%</td>
                      <td className="p-4">22%</td>
                      <td className="p-4">11%</td>
                    </tr>
                    <tr>
                      <td className="p-4">34%</td>
                      <td className="p-4">76%</td>
                      <td className="p-4">58%</td>
                    </tr>
                    <tr>
                      <td className="p-4">56%</td>
                      <td className="p-4">83%</td>
                      <td className="p-4">66%</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h3" className="mb-0">Product Sales</Card.Title>
            </Card.Header>
            <Card.Body className="pt-4">
              <div className="">
                <div className="panel panel-primary">
                  <div className="tabs-menu-body pt-0">
                    <div className="tab-content p-0">
                      <div className="tab-pane active">
                        <div className="table-responsive">
                          <Tab.Container id="left-tabs-example table-bordered" defaultActiveKey="AllProducts">
                            <Nav variant="pills" className='product-sale'>
                              <Nav.Item>
                                <Nav.Link eventKey="AllProducts" className="text-dark">All Products</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="Shipped" className="text-dark">Shipped</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="Pending" className="text-dark">Pending</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="Cancelled" className="text-dark">Cancelled</Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="AllProducts">
                                <AllProduct />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Shipped">
                                <Shipped />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Pending">
                                <Pending />
                              </Tab.Pane>
                              <Tab.Pane eventKey="Cancelled">
                                <Cancelled />
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </div>
  )
};

export default Dashboard;
