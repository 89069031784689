import React, { useState, useEffect } from 'react';
import styles from './clientModal.module.scss';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, InputGroup, ListGroup, Modal, Button } from 'react-bootstrap';
//import { Current } from '../../../Data/Pages/DataEditProfile';
import Select from 'react-select';
import axios from 'axios';




const ViewClientModal = ({ show, handleClose, selectedItem,fetchData }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const TOKEN = localStorage.getItem('token');
    const [NCFType, setNCFType] = useState([]);
    const API_NCF_Type = process.env.REACT_APP_API_URL + '/api/Client/GetNCFType';

    useEffect(() => {
        if (selectedItem?.imagen) {
          setImagePreview(`data:image/jpeg;base64,${selectedItem.imagen}`);
        }
      }, [selectedItem]);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const [NcfType] = await Promise.all([
              axios.get(API_NCF_Type, { headers: { Authorization: `Bearer ${TOKEN}` } }),
            ]);
            
            const processedData = NcfType.data.dataList.map(c => ({ value: c.id, label: c.descripcion }));
            setNCFType(processedData);
            console.log("NCF Type Data:", processedData);
          } catch (err) {
            console.error("Error fetching NCF Type data:", err);
          }
        };
      
        fetchData();
      }, [API_NCF_Type, TOKEN]);      

return (
    <Modal show={show} onHide={handleClose} dialogClassName={styles['custom-view-modal-width']} centered>
    <Modal.Header closeButton className="bg-primary text-white" >
      <Modal.Title className="fw-bold fs-4" >Expediente del cliente</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {selectedItem ? (
        <>
          <Row>
            <Col xl={4}>
                <Card>
                <Card.Header>
                    <Card.Title as="h3">Cliente</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="text-center chat-image mb-5">
                    <div className="avatar avatar-xxl chat-profile mb-3 brround">
                        <Link className="">{imagePreview && <img src={imagePreview} alt="avatar" className="brround" style={{ width: '150px', height: '150px'}}/> }</Link>
                    </div>
                    <div className="main-chat-msg-name">
                        <Link to={`${process.env.PUBLIC_URL}/pages/profile`}>
                        <h5 className="mb-1 text-dark fw-semibold">{selectedItem.nombres}</h5>
                        </Link>
                        <p className="text-muted mt-0 mb-0 pt-0 fs-13 text-dark fw-semibold">ID: {selectedItem.idCliente}</p>
                        <p className="text-muted mt-0 mb-0 pt-0 fs-13 text-dark fw-semibold">{selectedItem.email}</p>
                        <p className="text-muted mt-0 mb-0 pt-0 fs-13">{selectedItem.celular}</p>
                    </div>
                    </div>

                </Card.Body>
                <Card.Footer className="text-end">
                </Card.Footer>
                </Card>
                <Card className="panel-theme">
                <Card.Header>
                    <div className="float-start">
                    <Card.Title as="h3">Contactos</Card.Title>
                    </div>
                    <div className="clearfix"></div>
                </Card.Header>
                <Card.Body className="no-padding">
                    <ListGroup className="no-margin">
                    <ListGroup.Item className="d-flex ps-3">
                        <div className="social social-profile-buttons me-2">
                        <Link className="social-icon text-primary" to=""><i className="fe fe-phone"></i></Link>
                        </div>
                        <Form.Control type="text" id="celularTextBox" value={selectedItem.celular || "No disponible"} disabled/>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex ps-3">
                        <div className="social social-profile-buttons me-2">
                        <Link className="social-icon text-primary" to="#"><i className="fe fe-mail"></i></Link>
                        </div>
                        <Form.Control type="email" id="emailInput" value={selectedItem.email || "No disponible"} disabled/>
                    </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                </Card>
            </Col>
            <Col xl={8}>
                <Card>
                <Card.Header>
                    <Card.Title as="h3">Datos Generales</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={6} md={12}>
                            <Form.Group>
                            <Form.Label htmlFor="NombreLabel" className="fw-bold">Nombre</Form.Label>
                            <Form.Control type="text" id="NombreInput" value={selectedItem.nombres || "No disponible"} disabled/>
                            </Form.Group>
                        </Col>
                        <div className="col-lg-6 col-md-12">
                            <Form.Group>
                            <Form.Label htmlFor="CelularLabel" className="fw-bold">Tipo NCF</Form.Label>
                            <Form.Control 
                                type="text" 
                                id="CelularInput" 
                                value={NCFType.find((c) => c.value === selectedItem.tipoNCF)?.label || "No disponible"} 
                                disabled
                                />

                            </Form.Group>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}>
                            <Form.Group>
                                <Form.Label htmlFor="tipoIdentificacionLabel" className="fw-bold">Tipo de Identificación</Form.Label>  
                                <Form.Control type="text" id="tipoIdentificaciónInput" value={selectedItem.tipoIdentificacion || "No disponible"} disabled/>
                            </Form.Group>
                        </Col>
                        <div className="col-lg-6 col-md-12">
                            <Form.Group>
                                <Form.Label htmlFor="cedulaLabel" className="fw-bold">Identificación</Form.Label>
                                <Form.Control type="text" id="cedulaInput" value={selectedItem.cedula || "No disponible"} disabled/>
                            </Form.Group>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}>
                            <Form.Group>
                                <Form.Label htmlFor="otroCodigoLabel" className="fw-bold">Otro Código</Form.Label>  
                                <Form.Control type="text" id="otroCodigoInput" value={selectedItem.otroCodigo || "No disponible"} disabled/>
                            </Form.Group>
                        </Col>
                        <div className="col-lg-6 col-md-12">
                            <Form.Group>
                                <Form.Label htmlFor="descuentoLabel" className="fw-bold">Descuento</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control
                                    type="number"
                                    id="descuentoLabel"
                                    value={selectedItem.porcientoDescuento || 0}
                                    disabled
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}>
                            <Form.Group>
                                <Form.Label htmlFor="cuentaContableLabel" className="fw-bold">CuentaContable</Form.Label>  
                                <Form.Control type="text" id="cuentaContableInput" value={selectedItem.cuentaContable || "No disponible"} disabled/>
                            </Form.Group>
                        </Col>
                        <div className="col-lg-6 col-md-12 mt-12">
                            <Form.Group>
                                <Form.Group className="mg-b-10 mt-5">
                                    <Form.Check label={<span className="fw-bold">Empresa Extranjera</span>} type="switch" className="custom-switch ps-0 mg-b-10" value={selectedItem.empresaExtranjera || "No disponible"} disabled>
                                    </Form.Check>
                                </Form.Group>
                                
                                <Form.Group className="mg-b-10">
                                    <Form.Check label={<span className="fw-bold">Empresa Exenta</span>} type="switch" className="custom-switch ps-0 mg-b-10" value={selectedItem.empresaExcenta || "No disponible"} disabled>
                                    </Form.Check>
                                </Form.Group>
                            </Form.Group>
                        </div>
                    </Row>
                    <Form.Group>
                    <Form.Label className="fw-bold">Dirección</Form.Label>
                    <Form.Control as='textarea' className="form-control" value={selectedItem.direccion || "No disponible"} disabled rows={2}></Form.Control>
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="text-end">
                </Card.Footer>
                </Card>
                <Row>
                <Col lg={6}>
                    <Card>
                    <Card.Header>
                        <Card.Title as="h3">Referencias Personales</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        
                    </Card.Body>
                    </Card>
                </Col>
                <div className="col-lg-6">
                    <Card>
                    <Card.Header>
                        <Card.Title as="h3">Referencias Laborales</Card.Title>
                    </Card.Header>
                    <Card.Body>

                    </Card.Body>
                    </Card>
                </div>
                </Row>
            </Col>
            </Row>
        </>
      ) : (
        <p>No se han encontrado datos para este cliente.</p>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button className='bg-danger border-0'onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
);
};

export default ViewClientModal;
