import React from 'react';
import styles from './products.module.scss';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { ProductsData } from '../../Data/Pages/Products/products';

const Products = () => {

  return (

  <div className={styles.DataTable}>
    <PageHeader titles="GESTIÓN DE PRODUCTOS" active="Gestión de productos" items={['Productos']} />
    {/* <!-- Row --> */}
    <Row className="row-sm">
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title as='h3'>PRODUCTOS</Card.Title>
          </Card.Header>
          <Card.Body>
            <ProductsData />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* <!-- End Row --> */}
  </div>
)
  };

export default Products;
