import React from 'react';
import styles from './clients.module.scss';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';
import { ClientsData } from '../../Data/Pages/Clients/clients';

const Clients = () => {

  return (

  <div className={styles.DataTable}>
    <PageHeader titles="GESTIÓN DE CLIENTES" active="Gestión de clientes" items={['Clientes']} />
    {/* <!-- Row --> */}
    <Row className="row-sm">
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title as='h3'>CLIENTES</Card.Title>
          </Card.Header>
          <Card.Body>
            <ClientsData />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* <!-- End Row --> */}
  </div>
)
  };

export default Clients;
