import React, { useState, useEffect, useMemo, useRef  } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
import { Button, Table, Modal, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ClientModal from './ClientModal';
import ViewClientModal from './viewClientModal';
import Swal from 'sweetalert2';

export const ClientsData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false); 
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchBy, setSearchBy] = useState('');
  const timeoutRef = useRef(null); 

  const API_URL = process.env.REACT_APP_API_URL + '/api/Client';
  const TOKEN = localStorage.getItem('token');
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'idCliente',
        className: "text-left wd-15p border-bottom-0",
      },
      {
        Header: 'Tipo Identificación',
        accessor: 'tipoIdentificacion',
        className: "text-left wd-15p border-bottom-0",
      },
      {
        Header: 'Cédula',
        accessor: 'cedula',
        className: "text-start wd-15p border-bottom-0",
      },
      {
        Header: 'Nombre',
        accessor: 'nombres',
        className: "text-center wd-15p border-bottom-0",
      },
      {
        Header: 'Celular',
        accessor: 'celular',
        className: "text-center wd-15p border-bottom-0",
      },
      {
        Header: 'Email',
        accessor: 'email',
        className: "text-center wd-15p border-bottom-0",
      },
      {
        Header: '% Descuento',
        accessor: 'porcientoDescuento',
        className: "text-center wd-15p border-bottom-0",
      },
      {
        Header: 'Acción',
        accessor: 'accion',
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              {/* Icono de 3 puntos que abre el Dropdown */}
              <Dropdown align="end">
                <Dropdown.Toggle variant="link" className="btn-sm" id="dropdown-custom-components">
                <i className="fa fa-ellipsis-v fa-2x"></i> 
                </Dropdown.Toggle>
  
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleView(row.original)}
                    className="text-primary"
                  >
                    <i className="fa fa-eye fa-2x"  style={{ color: '#0b4d7b' }}></i>
                    <label style={{
                      fontSize: '1rem', // Letras grandes
                      color: 'black', // Color negro
                      fontWeight: 'bold',
                      marginLeft: '18px'
                    }}>
                      Expediente
                    </label>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleEdit(row.original)}
                    className="text-warning"
                  >
                    <i className="fa fa-wrench fa-2x" style={{ color: '#f7bc03' }}></i>
                    <label style={{
                      fontSize: '1rem', // Letras grandes
                      color: 'black', // Color negro
                      fontWeight: 'bold',
                      marginLeft: '21px'
                    }}>
                      Editar
                    </label>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleDelete(row.original)}
                    className="text-danger"
                  >
                    <i className="fa fa-trash fa-2x" style={{ color: '#c60107' }}></i>
                    <label style={{
                      fontSize: '1rem', // Letras grandes
                      color: 'black', // Color negro
                      fontWeight: 'bold',
                      marginLeft: '25px'
                    }}>
                      Borrar
                    </label>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  // Función para obtener los registros
  const fetchData = async (pageNumber = 1, pageSize = 10, searchBy = '') => {
    setLoading(true);
  
    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        params: {
          Filter: searchBy,
          pageNumber,
          pageSize,
        },
      });
  
      console.log('API Response:', response.data);
      if (response.data.dataList.length === 0) {
        setData([]);  // Establecer data vacía
        setTotalRecords(0);  // Establecer totalRecords en 0
      } else {
        setData(response.data.dataList);
        setTotalRecords(response.data.totalRecords); // Establecer totalRecords
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        // Si la respuesta es 404, no hay registros, así que vaciamos la lista
        setData([]);  // Esto hará que el datagrid quede vacío
        setTotalRecords(0);
      } else {
        setError(err.message || 'Error fetching data');
      }
    } finally {
      setLoading(false);
    }
  };  
  
  const handlePageChange = (newPage) => {
    setPageNumber(newPage); // Cambia el número de página
  };
  
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize); // Cambia el tamaño de la página
    setPageNumber(1); // Reinicia a la primera página para evitar inconsistencias
  };

  useEffect(() => {
    // Limpiamos el timeout anterior
    clearTimeout(timeoutRef.current);
    
    // Establecemos un nuevo timeout
    timeoutRef.current = setTimeout(() => {
      fetchData(pageNumber, pageSize, searchBy);
    }, 500); // 500ms de espera después de que el usuario deje de escribir
  }, [pageNumber, pageSize,searchBy]); // Dependencias: cambia cuando cualquiera de estos valores cambia
  
  const totalPages = Math.ceil(totalRecords / pageSize);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages, // Calculado a partir de totalRecords / pageSize
      initialState: { pageIndex: pageNumber - 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );   

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = tableInstance;

  const { globalFilter, pageIndex} = state;

  const nextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };
  // Actualizar número de página al cambiar en la tabla
  useEffect(() => {
    handlePageChange(pageIndex + 1);
  }, [pageIndex]);
  
  // Actualizar tamaño de página al cambiar en la tabla
  useEffect(() => {
    handlePageSizeChange(pageSize);
  }, [pageSize]);

  useEffect(() => {
    fetchData();
  }, [API_URL, TOKEN]);

  
  // Actualizar el estado con los registros modificados o agregados inmediatamente
  const updateItemsList = (newItemData) => {
    if (newItemData) {
      if (newItemData.idCliente) {
        // Si el registro tiene un ID, lo actualizamos
        setData((prevData) =>
          prevData.map((item) =>
            item.idCliente  === newItemData.idCliente  ? newItemData : item
          )
        );
      } else {
        // Si es un nuevo registro, lo agregamos al estado
        setData((prevData) => [...prevData, newItemData]);
      }
    }
  };

  const cellClass = (cell) => {
    if (cell.column.id === 'descripcion' || cell.column.id === 'categoria') {
      return 'text-start';
    }
    return 'text-center';
  };

  const handleView = (row) => {
    setSelectedItem(row); // Establecer el registro seleccionado
    setShowViewModal(true); // Mostrar el modal
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false); // Cerrar el modal
  };

  const handleCloseModal = () => {
    setSelectedItem(null); // Limpiar la selección del registro
    setShowModal(false); // Cerrar el modal
    fetchData(pageNumber, pageSize, searchBy);
  };

  const handleNewItem = () => {
    setSelectedItem(null); // Asegurarse de que no haya registro seleccionado
    setShowModal(true);
  };

  const handleEdit = (row) => {
    console.log('row ',row)
    setSelectedItem(row); // Establecer el registro seleccionado
    setShowModal(true);
  };

  const handleDelete = (row) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡Este registro será eliminado permanentemente!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Eliminar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${API_URL}/Inactivate/${row.idCliente}`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          });
          if (response.status === 200) {
            // Elimina el registro de la lista local
            setData((prevData) => prevData.filter((p) => p.idCliente !== row.idCliente));
            Swal.fire('¡Eliminado!', 'El registro ha sido eliminado.', 'success');
            fetchData(pageNumber, pageSize, searchBy); 
            setSearchBy('')
          }
        } catch (error) {
          Swal.fire('Error', 'Hubo un error al eliminar el registro.', 'error');
          setSearchBy('')
        }
      }
    });
  };
  

  if (loading) return <div>Cargando...</div>;
  if (error) 
  {
    console.log({error});
     setData([])
  };

  return (
    <>
      <div className="e-table px-5 pb-5 table-responsive">
        <div className="d-block">
          <select
            className="mb-4 table-border me-1"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((size) => (
              <option key={size} value={size}>
                Mostrar {size}
              </option>
            ))}
          </select>
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="primary"
              className="btn-lg d-flex align-items-center gap-2 bg-success border-0"
              onClick={handleNewItem}
            >
              <i className="typcn typcn-plus" style={{ fontSize: '1rem' }}></i>
              <span>Agregar</span>
            </Button>
          </div>
          <div className="d-flex justify-content-between">
        <input
          type="text"
          className="form-control mb-4"
          placeholder="Buscar..."
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        />
      </div>
          
        </div>

        <Table
          {...getTableProps()}
          className="table-bordered text-nowrap border-bottom"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.className}
                  >
                    <span className="tabletitle">{column.render("Header")}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-up"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr className="text-center" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={cellClass(cell)}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="pagination d-flex justify-content-between align-items-center">
          <Button onClick={previousPage} disabled={pageNumber === 1}>
            Anterior
          </Button>
          <span>
            Página {pageNumber} de {totalPages}
          </span>
          <Button onClick={nextPage} disabled={pageNumber === totalPages}>
            Siguiente
          </Button>
        </div>

        {/* Mostrar el total */}
        <div className="mt-2 text-right">
          <strong>Total de registros: </strong>{totalRecords}
        </div>
      </div>

      {showModal && (
        <ClientModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedItem={selectedItem}
          fetchData={fetchData} // Pasa la función que actualiza el listado
        />
      )}
      {showViewModal && (
        <ViewClientModal
          show={showViewModal}
          selectedItem={selectedItem}
          handleClose={handleCloseViewModal}
          fetchData={fetchData}
        />
      )}
    </>
  );
};
