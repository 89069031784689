import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, InputGroup,Col,Row } from 'react-bootstrap';
import { PDFDownloadLink, Document, Page, Text, View, Image } from '@react-pdf/renderer';
import Barcode from 'react-barcode';
import html2canvas from 'html2canvas';

const EtiquetaModal = ({ show, handleClose, product }) => {
  const [width, setWidth] = useState(200);

  const [height, setHeight] = useState(100);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const barcodeRef = useRef();

  const handleWidthChange = (e) => {
    setWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'DOP',
      minimumFractionDigits: 2,
    }).format(value);
  };

  // Convertir el código de barras a imagen cuando el componente se monta o el producto cambia
  useEffect(() => {
    if (barcodeRef.current) {
      html2canvas(barcodeRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        setBarcodeImage(imgData);
      });
    }
  }, [product, width, height]);

  const EtiquetaPDF = ({ product }) => (
    <Document>
      <Page size={[width, height]} style={{ padding: 5 }}>
        <View style={{ textAlign: 'center' }}>
          {/* Usando una fuente en negrita */}
          <Text style={{ fontSize: 10, textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>
            {product.descripcion}
          </Text>
          <Text style={{ fontSize: 10,  marginTop:'2px'}}>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>Precio: </Text>{formatCurrency(product.precio)}
          </Text>
          {barcodeImage && <Image src={barcodeImage} style={{ width: 'auto', height: 'auto', marginTop:'5px' }} />}
        </View>
      </Page>
    </Document>
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="bg-primary text-white">
        <Modal.Title className="fw-bold fs-4">Etiqueta del Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        {product ? (
          <>
            <h4>{product.descripcion}</h4>
            <p className="fw-bold">Precio:</p><p>${formatCurrency(product.precio)}</p>
            <Form>
            <Row className="mb-4">
              <Col lg={6} md={12}>
              <Form.Group as={InputGroup} className="mb-3">
                <InputGroup.Text>Ancho</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={width}
                  onChange={handleWidthChange}
                  min="1"
                  max="10"
                  step="0.1"
                />
              </Form.Group>
              </Col>
              <Col lg={6} md={12}>
              <Form.Group as={InputGroup} className="mb-3">
                <InputGroup.Text>Alto</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={height}
                  onChange={handleHeightChange}
                  min="50"
                  max="200"
                />
              </Form.Group>
                </Col>
            </Row>
            </Form>

            <div ref={barcodeRef}>
            <Barcode value={product.id} width={7} height={90} />
            </div>
          </>
        ) : (
          <p>No se ha seleccionado ningún producto.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>

        {/* Botón de descarga de PDF */}
        {product && (
          <PDFDownloadLink document={<EtiquetaPDF product={product} />} fileName="etiqueta.pdf">
            <Button variant="primary">
              Descargar PDF
            </Button>
          </PDFDownloadLink>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EtiquetaModal;
