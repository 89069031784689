import React, { Fragment, lazy, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './Layouts/Loader/Loader';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/Authentication/ProtectedRoutes/PrivateRoute';
import { isTokenValid, getToken, removeToken } from './components/Authentication/Auth/tokenService';
import { SessionProvider } from './components/Authentication/Auth/SessionContext'; // Asegúrate de usar la ruta correcta

import Dashboard from './components/Dashboard/Dashboard';
import Products from './components/Products/products';
import Clients from './components/Clients/clients';
import Billing from './components/Billing/billing';

const Auth = lazy(() => import('./components/Authentication/firebaseAuth/auth'));
const App = lazy(() => import('./components/app'));
const Error400 = lazy(() => import('./components/Authentication/errorPage/Error400/Error400'));
const ErrorPages = lazy(() => import('./components/ErrorPages'));
const AuthLogin = lazy(() => import('./components/Authentication/firebaseAuth/AuthLogin'));

const container = document.getElementById('root');
const root = createRoot(container);

const Index = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = getToken();
      if (isTokenValid(token)) {
        setIsAuthenticated(true);
      } else {
        removeToken();
        setIsAuthenticated(false);
      }
    };

    checkToken();

    window.addEventListener('storage', checkToken); // Escucha cambios en almacenamiento local
    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, []);

  return (
    <React.StrictMode>
      <Fragment>
        <BrowserRouter>
        <SessionProvider>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              {/* Rutas públicas */}
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
                <Route index element={<AuthLogin setIsAuthenticated={setIsAuthenticated} />} />
                <Route path={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/login`} element={<AuthLogin setIsAuthenticated={setIsAuthenticated} />} />
              </Route>

              {/* Rutas protegidas */}
              <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                  <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Dashboard />} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                  <Route path={`${process.env.PUBLIC_URL}/products`} element={<Products />} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                  <Route path={`${process.env.PUBLIC_URL}/clients`} element={<Clients />} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                  <Route path={`${process.env.PUBLIC_URL}/billing`} element={<Billing />} />
                </Route>
              </Route>

              {/* Páginas de error */}
              <Route path={`${process.env.PUBLIC_URL}`} element={<ErrorPages />}>
                <Route path={`${process.env.PUBLIC_URL}/authentication/errorpage/error400`} element={<Error400 />} />
              </Route>
            </Routes>
          </React.Suspense>
        </SessionProvider>
        </BrowserRouter>
      </Fragment>
    </React.StrictMode>
  );
};

root.render(<Index />);
reportWebVitals();
