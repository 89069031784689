import React, { createContext, useState, useEffect, useContext } from 'react';
import { getToken, isTokenValid } from './tokenService';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState({
    user: null,
    empresa: null,
    sucursal: null,
    roles: null,
    isAuthenticated: false,
  });

  useEffect(() => {
    const token = getToken();

    if (token && isTokenValid(token)) {
      
      const user = JSON.parse(localStorage.getItem('user'));
      const empresa = JSON.parse(localStorage.getItem('empresa'));
      const sucursal = JSON.parse(localStorage.getItem('sucursal'));
      const roles = JSON.parse(localStorage.getItem('roles'));
      setSession({
        user,
        empresa,
        sucursal,
        roles,
        isAuthenticated: true,
      });
    }
  }, []);

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {children}
    </SessionContext.Provider>
  );
};

// Define el hook useSession
export const useSession = () => {
  const context = useContext(SessionContext);
  
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  return context;
};
