import React, { useState, useRef, useEffect } from 'react';
import styles from './billing.module.scss';
import PageHeader from '../../Layouts/PageHeader/PageHeader';
import { Card, Col, Row, Table, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClientModal from '../../Data/Pages/Clients/ClientModal';

const Billing = () => {
  const [searchBy, setSearchBy] = useState('');
  const [products, setProducts] = useState([]); // Datos de productos filtrados
  const [selectedProducts, setSelectedProducts] = useState([]); // Productos seleccionados
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cache, setCache] = useState({}); // Cache de resultados
  const timeoutRef = useRef(null);

  const [client, setClient] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]); 
  const [imagePreview, setImagePreview] = useState(null);

  const [percentDiscount, setPercentDiscount] = useState(0);
  const [fullSaleDiscount, setFullSaleDiscount] = useState(0);

  const [showClientModal, setShowClientModal] = useState(false);

  const API_PRODUCT = process.env.REACT_APP_API_URL + '/api/Product';
  const API_Client = process.env.REACT_APP_API_URL + '/api/Client';
  const TOKEN = localStorage.getItem('token');

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'DOP',
      minimumFractionDigits: 2,
    }).format(value);
  };

  // Buscar productos desde el API con caché
  const fetchProducts = async (searchBy) => {
    if (cache[searchBy]) {
      setProducts(cache[searchBy]); // Usa el caché si ya existe
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(API_PRODUCT, {
        headers: { Authorization: `Bearer ${TOKEN}` },
        params: { Filter: searchBy },
      });

      const results = response.data.dataList.map((product) => ({
        value: product.id,
        label: `${product.descripcion} - $${product.precio}`,
        product,
      }));

      setCache((prevCache) => ({ ...prevCache, [searchBy]: results })); // Actualiza el caché
      setProducts(results);
    } catch (err) {
      setError(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async (searchBy) => {
    if (cache[searchBy]) {
      setClient(cache[searchBy]); // Usa el caché si ya existe
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(API_Client, {
        headers: { Authorization: `Bearer ${TOKEN}` },
        params: { Filter: searchBy },
      });

      const client_results = response.data.dataList.map((client) => ({
        value: client.idCliente,
        label: `${client.cedula} - ${client.nombres}`,
        client,
      }));
      setCache((prevCache) => ({ ...prevCache, [searchBy]: client_results })); // Actualiza el caché
      setClient(client_results);
    } catch (err) {
      setError(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  // Manejar cambios en el input de búsqueda
  const handleSearchProductChange = (inputValue) => {
    setSearchBy(inputValue);

    // Limpiar el timeout anterior y establecer uno nuevo
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (inputValue.trim() !== '') {
        fetchProducts(inputValue); // Solo consulta si hay texto
      } else {
        setProducts([]); // Limpia sugerencias si el input está vacío
      }
    }, 800); // Incrementado a 800ms
  };

    // Manejar cambios en el input de búsqueda
    const handleSearchClientChange = (inputValue) => {
      setSearchBy(inputValue);
  
      // Limpiar el timeout anterior y establecer uno nuevo
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (inputValue.trim() !== '') {
          fetchClients(inputValue); // Solo consulta si hay texto
        } else {
          setClient([]); // Limpia sugerencias si el input está vacío
        }
      }, 800); // Incrementado a 800ms
    };

    const handleClientSelect = (selectedOption) => {
      if (selectedOption) {
        const { client } = selectedOption;
    
        // Verificar si ya existe en la lista de clientes seleccionados
        setSelectedClient(client); // Establecer el cliente seleccionado
        setSearchBy(''); // Limpiar el input
        setClient([]); // Limpiar sugerencias
      }
    };
    

  // Manejar la selección de un producto
  const handleProductSelect = (selectedOption) => {
    if (selectedOption) {
      const { product } = selectedOption;

      // Verificar si ya existe en la tabla
      setSelectedProducts((prevProducts) => {
        if (prevProducts.find((p) => p.id === product.id)) {
          return prevProducts; // Evitar duplicados
        }
        return [
          ...prevProducts,
          {
            ...product,
            cantidad: 1,
            porcientoDescuento: percentDiscount > 0 ? percentDiscount : product.porcientoDescuento, // Aplica el descuento al nuevo producto
            descuentoDisabled: percentDiscount > 0, // Aplica la restricción de descuento si es necesario
          },
        ];
      });

      setSearchBy(''); // Limpiar el input
      setProducts([]); // Limpiar sugerencias
    }
  };

  // Manejar cambio en cantidad
  const handleQuantityChange = (productId, cantidad) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, cantidad: parseInt(cantidad, 10) || 1 } : product
      )
    );
  };

  // Manejar eliminación de producto
  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );
  };

  useEffect(() => {
    if (selectedClient?.imagen) {
      setImagePreview(`data:image/jpeg;base64,${selectedClient.imagen}`);
    }
  }, [selectedClient]);

  const handleRemoveClient = () => {
    setSelectedClient(null); // Limpia la selección de cliente
    setImagePreview(null); // Opcional: limpia la imagen
  };

  const handleOpenModal = (client) => {
    const clientDataForModal = {
      ...client
    };
    console.log('Esta es la data que se va al modal', client)
    setSelectedClient(clientDataForModal); // Seleccionar el cliente para editar
    setShowClientModal(true); // Abrir el modal
  };

  const handleCloseModal = () => {
    setShowClientModal(false);
    fetchClients(); // Opcional: recargar datos después de cerrar el modal
  };

  const handleUpdateClient = async (updatedData) => {
    setSelectedClient(updatedData); // Actualiza el estado con la data modificada
    setShowClientModal(false); // Cierra el modal
    fetchClients(updatedData.nombres); 
  };

  useEffect(() => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        porcientoDescuento: percentDiscount > 0 ? percentDiscount : product.porcientoDescuento,
        descuentoDisabled: percentDiscount > 0, // Propiedad para controlar el estado del input
      }))
    );
  }, [percentDiscount]);
  
  const handlePercentDiscountChange = (value) => {
    const discountValue = Number(value);
  
    setPercentDiscount(discountValue); // Actualiza el porcentaje global
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        porcientoDescuento: discountValue > 0 ? discountValue : 0, // Aplica descuento global o lo resetea
      }))
    );
  };
  
  const calculateSubtotal = () => {
    const subtotal = selectedProducts.reduce((acc, product) => {
      const precioConDescuento =
        product.precio - (product.precio * (product.porcientoDescuento || 0)) / 100;
      const totalPorProducto =
        precioConDescuento * product.cantidad +
        ((product.precio * (product.itbiss || 0)) / 100) * product.cantidad;
      return acc + totalPorProducto;
    }, 0);
  
    // Aplicar descuento global por porcentaje
    const subtotalAfterPercentDiscount = subtotal;
  
    // Aplicar descuento fijo
    const finalSubtotal = subtotalAfterPercentDiscount - fullSaleDiscount;
  
    return Math.max(finalSubtotal, 0); // Asegurar que no sea negativo
  };
  
  const calculateTotalitbiss = () => {
    const totalitbiss = selectedProducts.reduce((acc, product) => {
      const precioConDescuento =
        product.precio - (product.precio * (product.porcientoDescuento || 0)) / 100;
      
      // Calcular itbiss por producto con precio con descuento
      const itbissPorProducto = (precioConDescuento * (product.itbiss || 0)) / 100;
  
      // Sumar itbisss de cada producto
      return acc + itbissPorProducto * product.cantidad;
    }, 0);
  
    return totalitbiss; // Devuelve el total de itbiss
  };
  const calculateItbissForEachProduct = () => {
    return selectedProducts.map((product) => {
      // Asegúrate de que el descuento esté en un rango válido
      const precioConDescuento =
        product.precio - (product.precio * (product.porcientoDescuento || 0)) / 100;
  
      // Asegúrate de que el valor de ITBIS es válido
      const itbissPorProducto = (precioConDescuento * (product.itbiss || 0)) / 100;
  
      // Agrega el ITBIS calculado al objeto del producto
      return {
        ...product,
        itbissCalculado: itbissPorProducto, // Nuevo campo con el ITBIS calculado
      };
    });
  };

  return (
    <div className={styles.DataTable}>
      <PageHeader titles="VENTANA DE FACTURACIÓN" active="Facturación" items={['Ventas']} />
      <Row className="row-sm">
        <Col lg={8}>
          <Card className={styles['card-as-bill']}>
            <Card.Header className="bg-primary">
              <Card.Title as="h3" className="text-white fw-bold">PRODUCTOS</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* Componente Select para búsqueda */}
              <Select
                classNamePrefix="Select2"
                options={products} // Usa la lista filtrada
                onInputChange={handleSearchProductChange} // Búsqueda dinámica
                onChange={handleProductSelect} // Agregar a la tabla
                placeholder="Busca un producto..."
                noOptionsMessage={() => (loading ? 'Cargando...' : 'No hay coincidencias')}
                value={null} // Resetea el valor del input
                isClearable // Habilita la opción de limpiar
              />

              {/* Tabla de productos seleccionados */}
              <Table striped bordered hover responsive size="sm" className="mt-4">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Descuento</th>
                    <th>ITBIS</th>
                    <th>Total</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.length > 0 ? (
                    selectedProducts.map((product) => (
                      <tr key={product.id}>
                        <td>
                        <Form.Control
                          type="text"
                          value={product.descripcion || ''} // Asegúrate de que siempre sea una cadena
                          onChange={(e) =>
                            setSelectedProducts((prevProducts) =>
                              prevProducts.map((p) =>
                                p.id === product.id
                                  ? { ...p, descripcion: e.target.value } // Guarda el texto directamente
                                  : p
                              )
                            )
                          }
                        />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            min="0"
                            value={product.precio}
                            onChange={(e) =>
                              setSelectedProducts((prevProducts) =>
                                prevProducts.map((p) =>
                                  p.id === product.id
                                    ? { ...p, precio: parseFloat(e.target.value) || 0 }
                                    : p
                                )
                              )
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            min="1"
                            value={product.cantidad}
                            onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                          />
                        </td>
                        <td>
                        <Form.Control
                          type="number"
                          min="0"
                          max="100"
                          value={product.porcientoDescuento || 0}
                          disabled={product.descuentoDisabled}
                          onChange={(e) =>
                            setSelectedProducts((prevProducts) =>
                              prevProducts.map((p) =>
                                p.id === product.id
                                  ? { ...p, porcientoDescuento: parseFloat(e.target.value) || 0 }
                                  : p
                              )
                            )
                          }
                        />
                        </td>
                        <td>
                        {formatCurrency(calculateItbissForEachProduct())}


                        </td>
                        <td>
                        ${formatCurrency(
                          ((product.precio -
                            (product.precio * (product.porcientoDescuento || 0)) / 100) *
                            product.cantidad)
                        )}
                        </td>
                        <td className="text-center align-middle">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveProduct(product.id)}
                          >
                            <i className="fa fa-close"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center text-danger fs-4">
                        No hay productos seleccionados
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
            <div className={styles['paper-cut']}></div>
          </Card>
        </Col>
        <Col lg={4}>
        <Card className={styles['card-as-bill']}>
          <Card.Header className="bg-primary">
            <Card.Title as="h2" className="text-white fw-bold">CLIENTE</Card.Title>
          </Card.Header>
          <Card.Body>
            {!selectedClient?.cedula && (
              <Row className="mb-4 fs-4 d-flex align-items-center">
                <Col md={2}>
                  <Button>
                    <i className="fa fa-user-plus fa-2x" style={{ color: '#ffffff' }}></i>
                  </Button>
                </Col>
                <Col md={10}>
                  <Select
                    classNamePrefix="Select2"
                    className="form-control-lg border-2 border-primary fs-7 w-100"
                    options={client} // Usa la lista filtrada
                    onInputChange={handleSearchClientChange} // Búsqueda dinámica
                    onChange={handleClientSelect} // Manejar selección
                    placeholder="Buscar cliente..."
                    noOptionsMessage={() => (loading ? 'Cargando...' : 'No hay coincidencias')}
                    value={null} // Controla el valor del Select
                    isClearable
                  />
                </Col>
              </Row>
            )}
            {selectedClient && selectedClient.cedula ? (
              <>
                <Row className="d-flex align-items-center">
                  <div className="text-center chat-image mb-5 d-flex align-items-center">
                    <Col md={7} className="d-flex">
                      <div>
                        <Link to={`${process.env.PUBLIC_URL}/billing`} className="fs-6 text-start d-block fw-semibold">
                        {selectedClient.idCliente} {selectedClient.id} - {selectedClient.nombres}
                        </Link>
                        <p className="text-muted mt-0 mb-0 pt-0 fs-9 text-start">{selectedClient.cedula}</p>
                        <Link to={`${process.env.PUBLIC_URL}/billing`} className="fs-5 text-start d-block fw-semibold">
                          Descuento: {selectedClient.porcientoDescuento} %
                        </Link>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                      <Button className="btn d-flex align-items-center gap-2 bg-warning border-0 fw-semibold mb-2"  onClick={() => handleOpenModal(selectedClient)}>
                        <i className="fa fa-wrench fa-2x" style={{ color: '#ffffff' }}></i> Actualizar
                      </Button>
                      <Button className="btn d-flex align-items-center gap-2 bg-danger border-0 fw-semibold" onClick={handleRemoveClient}>
                        <i className="fa fa-user-times fa-2x" style={{ color: '#ffffff' }}></i> Remover
                      </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              </>
            ) : null}
          </Card.Body>
          <div className={styles['paper-cut']}></div>
        </Card>
        <Card className={styles['card-as-bill']}>
          <Card.Header className="bg-primary">
            <Card.Title as="h2" className="text-white fw-bold">DETALLE</Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              <Table striped bordered hover responsive>
                <tbody>
                  <tr>
                    <td>Descontar todos los artículos por ciento:</td>
                    <td>
                    <Form.Control
                      type="number"
                      min="0"
                      max="100"
                      value={percentDiscount}
                      onChange={(e) => handlePercentDiscountChange(e.target.value)}
                    />
                    </td>
                  </tr>
                  <tr>
                    <td>Descuento Venta completo:</td>
                    <td>
                      <Form.Control
                        type="number"
                        min="0"
                        value={fullSaleDiscount}
                        onChange={(e) => setFullSaleDiscount(Number(e.target.value))}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Subtotal:</td>
                    <td className="fw-bold">{formatCurrency(calculateSubtotal())}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">ITBIS (Total):</td>
                    <td className="fw-bold">{formatCurrency(calculateTotalitbiss())}</td>
                  </tr>
                </tbody>
              </Table>
              </div>
            </Card.Body>
            <div className={styles['paper-cut']}></div>
          </Card>
        </Col>
      </Row>
      {showClientModal && (
        <ClientModal
          show={showClientModal}
          handleClose={handleCloseModal}
          selectedItem={selectedClient}
          fetchData={handleUpdateClient}
        />
      )}
    </div>
  );
};

export default Billing;
