import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Card, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useSession } from '../../../components/Authentication/Auth/SessionContext'; 
import styles from './clientModal.module.scss';

const ClientModal = ({ show, handleClose, selectedItem, fetchData }) => {
  const TOKEN = localStorage.getItem('token');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [NCFType, setNCFType] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const API_NCF_Type = process.env.REACT_APP_API_URL + '/api/Client/GetNCFType';
  const API_CLIENT = process.env.REACT_APP_API_URL + '/api/Client';
  // Detectar si el Item está siendo editado o creado
  const isEditing = selectedItem !== null;
  // Agregar el estado `isProcessing` para manejar el bloqueo del botón de guardar
  const [isProcessing, setIsProcessing] = useState(false);
  const { session, setSession } = useSession();

  const IDENTIFICATION_OPTIONS = [
    { label: 'Cédula', value: 'cedula', maxLength: 11 },
    { label: 'Pasaporte', value: 'pasaporte', maxLength: 20 },
    { label: 'RNC', value: 'rnc', maxLength: 9 },
  ];
  // Estado centralizado para datos del registro
  const [ItemData, setItemData] = useState({
    idCliente: 0,
    tipoIdentificacion: '',
    cedula: '',
    nombres: '',
    celular: '',
    direccion: '',
    email: '',
    idUsuario: session.user?.Id,
    imagen: '',
    porcientoDescuento: 0,
    idSucursal: session.sucursal?.Id,
    otroCodigo: '',
    tipoNCF:  { value: 2, label: 'Comprobante de Consumo Final' },
    cuentaContable: '134',
    empresaExtranjera: false,
    empresaExcenta: false,
  });

  const handleFieldChange = (field, value) => {
    const numericValue = value !== '' ? parseFloat(value) : null;

    setItemData((prevData) => {
      const updatedData = { ...prevData, [field]: numericValue };
      return updatedData;
    });
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [NcfType] = await Promise.all([
          axios.get(API_NCF_Type, { headers: { Authorization: `Bearer ${TOKEN}` } }),
        ]);

        setNCFType(NcfType.data.dataList.map(c => ({ value: c.id, label: c.descripcion })));
      } catch (err) {
        setError(err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [API_NCF_Type, TOKEN]);

  // Actualizar datos del item cuando se selecciona para edición
  useEffect(() => {
      if (selectedItem && NCFType.length > 0) {
        console.log('Selected item:', selectedItem); // Verifica los datos del item seleccionado
        
        setItemData({
            id: selectedItem.idCliente || selectedItem.id || 0,
            tipoIdentificacion: IDENTIFICATION_OPTIONS.find((c) => c.value === selectedItem.tipoIdentificacion) || '',
            cedula: selectedItem.cedula || '',
            nombres: selectedItem.nombres || '',
            celular: selectedItem.celular || '',
            direccion: selectedItem.direccion || '',
            email: selectedItem.email || '',
            idUsuario: session.user?.Id,
            imagen: selectedItem.imagen || '',
            porcientoDescuento: selectedItem.porcientoDescuento || 0,
            idSucursal: session.sucursal?.Id,
            otroCodigo: selectedItem.otroCodigo || '',
            tipoNCF: NCFType.find((c) => c.value === selectedItem.tipoNCF) || 0,
            cuentaContable: String(selectedItem.cuentaContable) || '134',
            empresaExtranjera: selectedItem.empresaExtranjera || false,
            empresaExcenta: selectedItem.empresaExcenta || false,
        });
        console.log('NCF:', NCFType);
        console.log('Datos iniciales:', selectedItem);
        console.log('Estado final antes de enviar:', ItemData);
      } 
  }, [selectedItem, NCFType, session.user?.Id, session.sucursal?.Id]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setItemData((prev) => ({
          ...prev,
          [id]: id === 'codigoBarra' || id === 'cuentaContable' ? value : isNaN(value) || value === '' ? value : Number(value),
        }));
    };

    const handleIdentificacionChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Solo números
      
        if (ItemData.tipoIdentificacion === 'cedula' && value.length > 11) {
          value = value.slice(0, 11); // Limitar a 11 dígitos
        } else if (ItemData.tipoIdentificacion === 'rnc' && value.length > 9) {
          value = value.slice(0, 9); // Limitar a 9 dígitos
        }
      
        setItemData((prevData) => ({
          ...prevData,
          cedula: value,
        }));
    };
    const handleCelularChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Solo números
        if (value.length > 10) {
          value = value.slice(0, 10); // Limitar a 10 dígitos para Celular
        }
        setItemData((prevData) => ({
          ...prevData,
          celular: value,
        }));
      };
      
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setItemData(prev => {
      const updatedData = { ...prev, [name]: checked };
      if (name === 'permiteDescuento' && !checked) updatedData.porcientoDescuento = 0;
      return updatedData;
    });
  };

  useEffect(() => {
    if (isEditing && selectedItem?.imagen) {
      setImagePreview(`data:image/jpeg;base64,${selectedItem.imagen}`);
    }
  }, [isEditing, selectedItem]);

// Función para manejar la carga de la imagen (de archivo) y convertirla a base64
const handleImageChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const fileType = file.type.split('/')[1];
    const allowedTypes = ['jpeg', 'png'];
    if (!allowedTypes.includes(fileType)) {
      Swal.fire('Error', 'Solo se permiten archivos .jpg o .png', 'error');
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      // Almacena la imagen convertida a base64
      setImagePreview(reader.result); // Vista previa en base64
      setItemData(prev => ({ ...prev, imagen: reader.result.split(',')[1] })); // Almacena solo la parte base64 sin el encabezado
    };
    reader.readAsDataURL(file); // Lee el archivo como base64
  }
};

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Lista de campos requeridos con sus nombres legibles
    const requiredFields = [
      { key: "nombres", label: "Nombre" },
      { key: "tipoIdentificacion", label: "Tipo de Identificación" },
      { key: "cedula", label: "Identificación" },
      { key: "tipoNCF", label: "Tipo de NCF" },
    ];

     // Encuentra los campos faltantes
     const missingFields = requiredFields
     .filter((field) => !ItemData[field.key])
     .map((field) => `<li><b>${field.label}</b></li>`);

    
     if (missingFields.length > 0) {
      // Crea un mensaje de error con los campos faltantes en lista
      const errorMessage = `
        Por favor complete los siguientes campos requeridos:
        <ul style="text-align: left; margin-left: 35px; list-style-type: disc;">
          ${missingFields.join("")}
        </ul>
      `;
      Swal.fire({
        title: "Error",
        html: errorMessage, // Usar 'html' en lugar de 'text'
        icon: "error",
      });
      return;
    }

    if (ItemData.precio < ItemData.costo) {
      Swal.fire('Error', 'El precio debe ser mayor o igual al costo.', 'error');
      return;
    }
    
    setIsProcessing(true); // Bloquear el botón de guardar
  
    try {
      setLoading(true);

      const ItemDataToSend = {
        ...ItemData,
        tipoNCF: ItemData.tipoNCF?.value || ItemData.tipoNCF, // Enviar solo el valor
        tipoIdentificacion: ItemData.tipoIdentificacion?.value, // Enviar directamente
      };
  
      console.log('ItemDataToSend ', ItemDataToSend);
  
      const apiCall = isEditing 
        ? axios.put(API_CLIENT, ItemDataToSend, { headers: { Authorization: `Bearer ${TOKEN}` } })
        : axios.post(API_CLIENT, ItemDataToSend, { headers: { Authorization: `Bearer ${TOKEN}` } });
  
      const response = await apiCall;
  
      if (response.status === (isEditing ? 200 : 201)) {
        Swal.fire({
          icon: 'success',
          title: isEditing ? 'Registro editado con éxito' : 'Registro agregado con éxito',
          text: isEditing ? 'El registro ha sido actualizado correctamente.' : 'El registro ha sido agregado correctamente.'
        });


        fetchData(ItemDataToSend)
        handleClose();
      }
    } catch (error) {
      console.error('Error al guardar el registro:', error);
    } finally {
      setLoading(false);
      setIsProcessing(false); // Desbloquear el botón después de la operación
    }
  };  

  return (
    <Modal
    show={show}
    onHide={handleClose}
    dialogClassName={styles['custom-modal-width']} // Clase personalizada para el ancho
    centered // Para centrar el modal
  >
    <Modal.Header closeButton className="bg-primary text-white" style={{ border: '2px solid red' }}>
      <Modal.Title className="fw-bold fs-4">{isEditing ? 'Editar clientes' : 'Agregar clientes'}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Card className={styles.productModalCard}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col lg={3} md={12}>
                <Form.Group controlId="nombres">
                  <Form.Label className="required-label fw-bold fs-6">Nombre</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nombre del cliente"
                    value={ItemData.nombres}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="tipoIdentificacion">
                    <Form.Label className="required-label fw-bold fs-6">Tipo ID</Form.Label>
                    <Select
                        options={IDENTIFICATION_OPTIONS}
                        value={ItemData.tipoIdentificacion}
                        onChange={(selected) => setItemData((prevData) => ({
                            ...prevData,
                            tipoIdentificacion: selected,
                            cedula: '', // Resetea cedula si cambia el tipo
                        }))}
                        placeholder="Seleccione"
                    />
                </Form.Group>
              </Col>

              <Col lg={3} md={12}>
                <Form.Group controlId="cedula">
                <Form.Label className="required-label fw-bold fs-6">Identificación</Form.Label>
                <InputGroup>
                    <Form.Control
                    required
                    type="text"
                    placeholder="Ej. 40255528526"
                    value={ItemData.cedula}
                    onChange={handleIdentificacionChange}
                    />
                </InputGroup>
                </Form.Group>
            </Col>
            <Col lg={3} md={12}>
                  <Form.Group controlId="tipoNCF">
                    <Form.Label className="required-label fw-bold fs-6">Tipo de NCF</Form.Label>
                    <Select
                    required
                      options={NCFType}
                      value={ItemData.tipoNCF}
                      onChange={(selected) =>
                        setItemData((prevData) => ({
                          ...prevData,
                          tipoNCF: selected,
                        }))
                      }
                      placeholder="Seleccione"
                    />
                  </Form.Group>
                </Col>
            </Row>
            <Row className="mb-4">
            <Col lg={3} md={12}>
                <Form.Group controlId="email">
                  <Form.Label className="fw-bold fs-6">E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ej. micorreo@miDominio.com"
                    value={ItemData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="porcientoDescuento">
                  <Form.Label className="fw-bold fs-6">Descuento</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>%</InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Ej. 10"
                      value={ItemData.porcientoDescuento || 0}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={3} md={12}>
                <Form.Group controlId="otroCodigo">
                  <Form.Label className="fw-bold fs-6">Otro Código</Form.Label>
                  <Form.Control
                    type="text"
                    value={ItemData.otroCodigo || 0}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col lg={3} md={12}>
                <Form.Group controlId="celular">
                <Form.Label className="fw-bold fs-6">Celular</Form.Label>
                <InputGroup>
                    <Form.Control
                    type="text"
                    placeholder="Ej. 8092252334"
                    value={ItemData.celular}
                    onChange={handleCelularChange}
                    />
                </InputGroup>
                </Form.Group>
            </Col>
            </Row>
            <Row className="mb-4">
                <Col lg={3} md={12}>
                    <Form.Group controlId="cuentaContable">
                    <Form.Label className="required-label fw-bold fs-6">Cuenta Contable</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={ItemData.cuentaContable}
                        onChange={handleInputChange}
                    />
                    </Form.Group>
                </Col>
                <Col lg={6} md={12}>
                    <Form.Group controlId="direccion">
                    <Form.Label className="fw-bold fs-6">Dirección</Form.Label>
                    <Form.Control
                        type="text"
                        value={ItemData.direccion}
                        onChange={handleInputChange}
                    />
                    </Form.Group>
                </Col>
                <Col lg={3} md={12}>
                <Form.Group className="d-flex flex-column justify-content-center h-100">
                  <Form.Check
                    label="Empresa Extranjera"
                    type="switch"
                    className="custom-switch ps-0 mg-b-0"
                    checked={ItemData.empresaExtranjera}
                    onChange={handleSwitchChange}
                    name="empresaExtranjera"
                  />
                  
                  <Form.Check
                    label="Empresa Exenta"
                    type="switch"
                    className="custom-switch ps-0 mg-b-0"
                    checked={ItemData.empresaExcenta}
                    onChange={handleSwitchChange}
                    name="empresaExcenta"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12}>
                <Form.Group controlId="imagen">
                  <Form.Label className="fw-bold fs-6">Imagen</Form.Label>
                  <Form.Control
                    type="file"
                     accept=".jpg,.png"
                    onChange={handleImageChange}
                  />
                  {imagePreview && <img src={imagePreview} alt="Vista previa" style={{ width: '150px', height: '150px', marginTop: '10px' }} />}
                  <Form.Control.Feedback type="invalid">Archivo válido</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Card.Body>
      </Card>
    </Modal.Body>
    <Modal.Footer>
      <Button className='bg-danger border-0' onClick={handleClose}>
        Cerrar
      </Button>
      <Button 
        className='bg-success border-0'
        onClick={handleSubmit} 
        disabled={isProcessing} // Bloquear el botón cuando esté en procesamiento
      >
        {isProcessing ? 'Guardando...' : 'Guardar Producto'}
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default ClientModal; 
