export const getToken = () => {
  return localStorage.getItem('token');
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica el payload del JWT
    return payload.exp * 1000 > Date.now(); // Verifica si el token no ha expirado
  } catch (error) {
    return false; // Token inválido
  }
};
