import React, { useState, useEffect } from 'react';
import { Modal, Card, Row, Col, Form, Button } from "react-bootstrap";
import styles from './ProductModal.module.scss';

const ProductViewModal = ({ show, handleClose, selectedProduct }) => {
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        if (selectedProduct?.imagen) {
          setImagePreview(`data:image/jpeg;base64,${selectedProduct.imagen}`);
        }
      }, [selectedProduct]);

      const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'DOP',
          minimumFractionDigits: 2,
        }).format(value);
      };
    return (
      <Modal show={show} onHide={handleClose} dialogClassName={styles['custom-modal-width']} centered>
      <Modal.Header closeButton className="bg-primary text-white" >
        <Modal.Title className="fw-bold fs-4">Detalles del Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedProduct ? (
          <>
           <Row className="mb-4">
              {/* Primer Card */}
              <Col lg={3} md={12}>
                <Card>
                  <Card.Body>
                    <Card.Title className="fw-bold">Imagen del producto</Card.Title>
                        {imagePreview && <img src={imagePreview} alt="Vista previa" style={{ width: '250px', height: '250px', marginTop: '10px' }} />}   
                  </Card.Body>
                </Card>
              </Col>

              {/* Segundo Card */}
              <Col lg={3} md={12}>
                <Card>
                  <Card.Body>
                    <Card.Title className="fw-bold">Descripción</Card.Title>
                    <Card.Text>{selectedProduct.descripcion || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Costo</Card.Title>
                    <Card.Text>{formatCurrency(selectedProduct.costo) || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Inventariable</Card.Title>
                    <Card.Text>{selectedProduct.inventariable ?  "Sí" : "No"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Almacenamiento Mínimo</Card.Title>
                    <Card.Text>{selectedProduct.stockMinimo || "No disponible"}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Tercer Card */}
              <Col lg={3} md={12}>
                <Card>
                  <Card.Body>
                    <Card.Title className="fw-bold">Categoría</Card.Title>
                    <Card.Text>{selectedProduct.categoria || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Precio</Card.Title>
                    <Card.Text>{formatCurrency(selectedProduct.precio) || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Procedimiento Dental</Card.Title>
                    <Card.Text>{selectedProduct.precedimientoDental ?  "Sí" : "No"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Almacenamiento Máximo</Card.Title>
                    <Card.Text>{selectedProduct.stockMaximo || "No disponible"}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
               {/* Cuarto Card */}
               <Col lg={3} md={12}>
                <Card>
                  <Card.Body>
                    <Card.Title className="fw-bold">Código de Barra</Card.Title>
                    <Card.Text>{selectedProduct.codigoBarra || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">ITBIS</Card.Title>
                    <Card.Text>{selectedProduct.itbiss || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Descuento</Card.Title>
                    <Card.Text>{selectedProduct.porcientoDescuento || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Existencia Total</Card.Title>
                    <Card.Text>{selectedProduct.existenciaTotal || "No disponible"}</Card.Text>

                    <Card.Title className="fw-bold mt-5">Cuenta Costo Venta</Card.Title>
                    <Card.Text>{selectedProduct.cuentaCostoVenta || "No disponible"}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <p>No se han encontrado datos para este producto.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-danger border-0'onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductViewModal;
